<template>
  <div class="fb">
      <div class="tab-catalog-01">
        <div @click="goRelease" class="btn"><i class="el-icon-plus"></i>新增发布职位</div>
        <a href="javascript:void(0);" @click="changestatus()" :class="{atthis: !status}">全部</a>
        <a href="javascript:void(0);" @click="changestatus(1)" :class="{atthis: status === 1}">上架</a>
        <a href="javascript:void(0);" @click="changestatus(2)" :class="{atthis: status === 2}">下架</a>
      </div>
      <p class="tips">今日剩余职位刷新次数：{{cs}}次</p>
      <ul class="zw">
        <li v-for="item in pRecruitList" :key="item.id">
          <div class="title">
            <div class="btn">
              <a href="javascript:void(0);" v-if="item.status === 1" @click="updateZw(item, 2)" class="xj">下架</a>
              <a href="javascript:void(0);" v-if="item.status === 2" @click="updateZw(item, 1)" class="sj">上架</a>
              <!-- <a href="javascript:void(0);" class="tg">推广</a> -->
            </div>
            <h5>{{item.jobName}}<span>{{item.provinName}}·{{item.cityName}}</span></h5></div>
          <div class="nr">
            <div class="btn">
              <i class="sx" @click="refushGW(item.id)"></i>
              <i class="bj" @click="$router.push('/recruitCenter/release?id='+item.id)"></i>
              <i class="sc" @click="del(item)"></i>
            </div>
            <span><i class="time"></i>{{item.promotionTime}}</span>
            <span><i class="eys"></i>{{item.viewsCount}}</span>
            <span><i class="yt">已投：</i>{{item.deliveryCount}}</span>
            <span style="color: #0780E6; cursor: pointer" @click="$router.push('/resume?id='+item.id)">查看简历</span>
            <span><i class="yt">可看：</i>{{item.pushCount}}</span>
            <span style="color: #0780E6; cursor: pointer" @click="$router.push('/resume?rwzt=1&id='+item.id)">查看</span>
          </div>
        </li>
      </ul>
      <el-empty style="padding: 60px 0 300px; background: #fff;" v-if="!total" :image-size="100"></el-empty>
      <!-- <el-footer class="listPage" v-if="total > 0">
        <el-pagination
          :total="total"
          :current-page="search.pageNum"
          :page-size="search.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="getcollection"
          @current-change="getcollection"
        ></el-pagination>
      </el-footer> -->
      
      <el-dialog
        title="支付"
        :visible.sync="dialogVisible"
        class="paytc"
        width="300px">
        <p class="zf">职位刷新付费：<span>{{payPrice}}元</span></p>
        <div class="qrcode" v-if="dialogVisible" style="text-align: center" ref="qrCodeUrl"></div>
        <p>请打开微信扫码支付</p>
      </el-dialog>

      <el-dialog
        title="下架"
        :visible.sync="xjdialogVisible"
        width="500px">
       <el-form ref="form" label-width="80px">
          <el-form-item label="下架原因">
            <el-select style="width: 100%" v-model="hideRemark" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
       </el-form>
       <div slot="footer" class="dialog-footer">
          <el-button @click="xjdialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateZw()">确 定</el-button>
        </div>
      </el-dialog>
  </div>
</template>
<script>
import { pRecruit, getenterprise,  updatepRecruit, removeRecruit, refush, pay, payDetail, orderDetail, getSysxcs } from '@/api/zp'
import QRCode from 'qrcodejs2'
export default {
  name: 'Releaseed',
  data() {
    return {
      xjdialogVisible: false,
      dialogVisible:false,
      issned: false,
      pRecruitList: [],
      total: 0,
      status: '',
      payPrice: 0,
      cs: 0,
      hideRemark: '',
      options: [{
        label: '取消岗位',
        value: 1
      },{
        label: '已招聘到岗-通过平台',
        value: 2
      },{
        label: '已招聘到岗-其他渠道',
        value: 3
      }]
    };
  },
  components: {
  },
  created(){
    this.getpRecruit()
    this.loadenterprise()
    this.getSysxcs()
    payDetail().then(res => {
      this.payPrice = res.data.discountPirce
    })
  },
  watch: {
    dialogVisible(v) {
      if(v) {
        this.checkedPay()
      }
    }
  },
  methods: {
    getSysxcs(){
      getSysxcs().then(res => {
        this.cs =  res.data
      })
    },
    checkedPay(){
      orderDetail(this.orderId).then(res => {
        if(res.data.payStatus === 3){
          this.dialogVisible = false
          this.$message.success('支付成功')
        } else {
          setTimeout(() => {
            this.checkedPay()
          }, 2000)
        }
      })
    },
    refushGW(id){
      refush(id).then(res => {
        this.getSysxcs()
        if(res.data.nowRefush > res.data.feeRefush){
          this.$confirm('当日刷新次数已用完，是否前往购买?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
             pay({
              orderType: 2,
              payStyle: 1,
              pushId: id,
              wxCode: ''
             }).then(res => {
              this.dialogVisible = true
              this.orderId = res.data.orderId
              this.$nextTick(() => {
                new QRCode(this.$refs.qrCodeUrl, {
                  text: res.data.shortUri, 
                  width: 194,
                  height: 194,
                  colorDark: "black",
                  colorLight: "white",
                  correctLevel: QRCode.CorrectLevel.H,
                }); 
              })
              
              // window.wx.chooseWXPay({
              //   timestamp: res.data.timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
              //   nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
              //   package: res.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              //   signType: res.data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              //   paySign: res.data.paySign, // 支付签名
              //   success: function (res1) {
              //     console.log(res1)
              //   }
              // });
             })
          })
        } else {
          this.$message.success('刷新成功')
        }
      })
    },
    // 跳转
    goRelease(){
      if(this.issned){
        this.$router.push('/recruitCenter/release')
      } else {
        this.$message.warning('请先填写企业信息并且通过审核才能发布职位！')
        this.$router.push('/recruitCenter')
      }
    },
     // 获取
    loadenterprise(){
      getenterprise().then(res => {
        if(res.data){
          this.issned = res.data.examineStatus===2
        }
      })
    },
    // 获取发布
    getpRecruit(){
      pRecruit({status: this.status}).then(res => {
        this.pRecruitList = res.rows
        this.total = res.total
      })
    },
    // 点击切换
    changestatus(status){
      this.status = status
      this.getpRecruit()
    },
    // 上、下架
    updateZw(item, type){
      if(!item && !type) {
        item = this.xjItem
        type = 2
        if(!this.hideRemark) {
          this.$message.warning('请选择下架原因')
          return
        }
        item.hideRemark = this.hideRemark
      }
      if(type===2 && !this.hideRemark){
        this.xjItem = item
        this.xjdialogVisible = true
        return
      }
      updatepRecruit({
        ...item,
        status: type
      }).then(() => {
        this.$message.success(type===1?'上架成功':'下架成功')
        this.xjdialogVisible = false
        this.hideRemark = ''
        this.getpRecruit()
      })
    },
    // 删除文件
    del(item){
       this.$confirm('此操作将删除该职位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          removeRecruit([item.id]).then(() => {
          this.$message.success('删除成功')
          this.getpRecruit()
        })
      })
    }
  },
};
</script>
<style scoped lang="scss">
.tips{
  line-height: 30px;
  font-size: 16px;
  color: red;
  margin-top: 15px;
}
.tab-catalog-01{
  line-height: 66px;
  padding: 16px 16px 0 16px;
  height: 98px;
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;
  a{
    color: #666666;
    float: left;
    margin-right: 120px;
    font-size: 18px;
    &.atthis{
      border-bottom: 4px solid #0780E6;
      height: 82px;
      color: #0780E6;
    }
  }
  .btn{
    float: right;
    height: 41px;
    background: #0780E6;
    border-radius: 2px 2px 2px 2px;
    color: #fff;
    cursor: pointer;
    line-height: 41px;
    padding: 0 20px;
    border-radius: 2px 2px 2px 2px;
    margin-top: 12px;
    &:hover{
      opacity: .8;
    }
  }
}
.zw{
  overflow: hidden;
  margin-top: 10px;
  li{
    margin-bottom: 20px;
    background: #fff;
    padding: 16px;
    .title{
      overflow: hidden;
      padding-bottom: 16px;
      .btn{
        float: right;
        a{
          float: left;
          padding: 0 10px 0 18px;
          height: 30px;
          line-height: 30px;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #0780E7;
          margin-left: 20px;
          color: #0780E6;
          font-size: 14px;
          background: transparent url(../../assets/img/center/icon-sj.png) no-repeat 8px 7px;
          background-size: 4px;
          background-position-y: center;
          &.xj{
            background: transparent url(../../assets/img/center/icon-xj.png) no-repeat 8px 7px;
            background-size: 4px;
            background-position-y: center;
          }
          &.tg{
            padding: 0 10px 0 26px;
            background: transparent url(../../assets/img/center/icon-tg.png) no-repeat 8px 9px;
            background-size: 13px;
            background-position-y: center;
          }
          &:hover{
            opacity: .8;
          }
        }
      }
      h5{
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        span{
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-left: 15px;
        }
      }
    }
    .nr{
      border-top: 1px solid #EEEEEE;
      overflow: hidden;
      padding-top: 16px;
      .btn{
        float: right;
        i{
          float: left;
          cursor: pointer;
          width: 26px;
          height: 26px;
          margin-left: 30px;
          background: transparent url(../../assets/img/center/icon-sx.png) no-repeat left top;
          background-size: 26px;
          &.bj{
            background: transparent url(../../assets/img/center/icon-bj.png) no-repeat left top;
            background-size: 26px;
          }
          &.sc{
            background: transparent url(../../assets/img/center/icon-sc.png) no-repeat left top;
            background-size: 26px;
          }
          &:hover{
            opacity: .8;
          }
        }
      }
      span{
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-right: 50px;
        line-height: 19px;
        float: left;
        i{
          display: inline-block;
          width: 19px;
          height: 19px;
          float: left;
          margin-right: 5px;
          &.time{
            background: transparent url(../../assets/img/news/icon-yd.png) no-repeat left top;
            background-size: 18px;
          }
          &.eys{
            background: transparent url(../../assets/img/news/icon-eys.png) no-repeat left top;
            background-size: 18px;
          }
          &.yt{
            white-space: nowrap;
            width: 30px;
            font-style: normal;
            color: #CCCCCC;
          }
        }
      }
    }
  }
}
.paytc{
  /deep/ .el-dialog__body{
    padding: 0 15px 15px;
    text-align: center;
    img{
      margin: 0 auto;
    }
    p{
      font-size: 14px;
      line-height: 30px;
      color: #999;
      span{
        color: red;
      }
      &.zf{
        font-size: 16px;
        color: #000;
        line-height: 40px;
      }
    }
  }
}
</style>
